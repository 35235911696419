<template>
    <div>
        <form class="form"
            novalidate="novalidate"
            id="st_add_institution_form"
            @submit.stop.prevent="onSubmit()"
        >
            <div class="form-group">
                <label>
                    {{ fields.name.label }} *
                </label>
                <st-input-text
                    v-model="model[fields.name.name]"
                    :ref="fields.name.name"
                    :name="fields.name.name"
                />
            </div>
            <div class="form-group">
                <label>
                    {{ fields.type.label }} *
                </label>
                <b-form-select
                    v-model="model[fields.type.name]"
                    :options="fields.type.options"
                    value-field="value"
                    text-field="name"
                    :ref="fields.type.name"
                    :name="fields.type.name"
                    @change="onChange($event, fields.type)"
                ></b-form-select>
            </div>
            <div class="form-group">
                <label>
                    {{ fields.description.label }}
                </label>
                <b-form-textarea
                    v-model="model[fields.description.name]"
                    :ref="fields.description.name"
                    :name="fields.description.name"
                    :placeholder="fields.description.placeholder"
                ></b-form-textarea>
            </div>
            <div v-show="model[fields.type.name]" class="form-group">
                <label v-show="model[fields.type.name] === 'county_council'">
                    {{ $t('INSTITUTION.FIELDS.UAT') }} *
                </label>
                <label v-show="isCityHallOrServices">
                    {{ fields.county_id.label }} *
                </label>
                <st-autocomplete
                    v-model="model[fields.county_id.name]"
                    :options="fields.county_id.options"
                    :ref="fields.county_id.name"
                    :name="fields.county_id.name"
                    :disabled="true"
                    :model="model"
                    :formValidation="fv"
                    :fields="fields"
                >
                </st-autocomplete>
            </div>
            <div v-show="isCityHallOrServices" class="form-group">
                <label v-if="model[fields.type.name] === 'services'">
                    {{ $t('INSTITUTION.FIELDS.UAT') }}
                </label>
                <label v-else>
                    {{ $t('INSTITUTION.FIELDS.UAT') }} *
                </label>
                <st-autocomplete
                    v-model="model[fields.locality_id.name]"
                    :options="fields.locality_id.options"
                    :ref="fields.locality_id.name"
                    :name="fields.locality_id.name"
                    :disabled="!isCityHallOrServices"
                    :model="model"
                    :formValidation="fv"
                    :fields="fields"
                >
                </st-autocomplete>
            </div>
            <div class="form-group">
                <label>
                    {{ fields.external_link.label }}
                </label>
                <st-input-text
                    v-model="model[fields.external_link.name]"
                    :ref="fields.external_link.name"
                    :name="fields.external_link.name"
                />
            </div>
            <div class="form-group">
                <label>
                    {{ fields.op_details_text.label }}
                </label>
                <b-form-textarea
                    v-model="model[fields.op_details_text.name]"
                    :ref="fields.op_details_text.name"
                    :name="fields.op_details_text.name"
                    :rows="6"
                ></b-form-textarea>
            </div>
            <div class="form-group mb-6">
                <label>
                    {{ fields.status.label }}
                </label>
                <b-form-radio-group
                    size="lg"
                    v-model="model[fields.status.name]"
                    :name="fields.status.name"
                    :options="fields.status.options"
                >
                </b-form-radio-group>
            </div>
            <div class="form-group">
                <label>{{ $t("INSTITUTION.LOGO_LABEL") }}</label>
                <div class="d-flex justify-content-center">
                    <st-file
                        :maxFiles="1"
                        :maxFileSize="10"
                        :acceptedFileTypes="['image/jpeg','image/png']"
                        @change="uploadLogo"
                    >
                    </st-file>
                </div>
            </div>

            <hr class="pt-10"/>
            <div class="form-group">
                <label>
                    {{ $t("INSTITUTION.USEFUL_LINKS_CSV_UPLOAD_LABEL") }}.
                    ({{ $t("INSTITUTION.USEFUL_LINKS_CSV_UPLOAD_INFO") }})
                </label>
                <div class="d-flex justify-content-center">
                    <st-file
                        htmlId="utilsLinksUploader"
                        :maxFiles="1"
                        :maxFileSize="10"
                        :acceptedFileTypes="['text/csv', 'application/vnd.ms-excel']"
                        @change="uploadUsefulLinksCsv"
                    >
                    </st-file>
                </div>
            </div>

            <hr class="pt-10"/>
            <div>
                <b-form-checkbox
                    class="mb-4"
                    size="lg"
                    v-model="model[fields.test_org.name]"
                    :value="true"
                    :unchecked-value="false"
                >
                {{ fields.is_test.placeholder }}
                </b-form-checkbox>
                <b-form-checkbox
                    size="lg"
                    v-model="model[fields.is_citizen_visible.name]"
                    :value="true"
                    :unchecked-value="false"
                    :disabled="isInactive"
                >
                {{ fields.is_citizen_visible.label }}
                </b-form-checkbox>
            </div>
        </form>
    </div>
</template>

<script>
import {  mapGetters } from 'vuex';
import { OrganisationModel } from "@/modules/administrator/models/organisation-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = OrganisationModel;

const formSchema = new FormSchema([
    fields.name,
    fields.description,
    fields.county_id,
    fields.locality_id,
    fields.status,
    fields.test_org,
    fields.type,
    fields.is_citizen_visible,
    fields.external_link,
    fields.op_details_text
]);

import { mapActions } from 'vuex';
import Message from '@/shared/message/message';
export default {
    name: 'AddInstitutionForm',
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            fv: null,
            logo: null,
            inactiveStatus: 'inactive',
            usefulLinksCsv: null,
        };
    },
    computed: {
        ...mapGetters({
            defaultCountyId: 'shared/defaultCountyId',
            uploadAccessToken: "administrator/uploadAccessToken",
        }),
        isCityHallOrServices() {
            return this.model.type === 'city_hall'
                || this.model.type === 'services';
        },
        disableFileUpload() {
            return !this.uploadAccessToken;
        },
        isInactive() {
            return this.model.status === this.inactiveStatus;
        }
    },
    methods: {
        ...mapActions({
            addInstitution: 'administrator/orgForm/addInstitution',
            doUpload: 'administrator/orgForm/upload',
            doUpdate: 'administrator/orgForm/updateInstitution',
        }),
        async manageInstitution() {
            const status = await this.fv.validate();
            if (status === 'Valid') {
                const counties = this.fields.county_id.options;
                const selectedCounty = counties.find( county => county.value === this.model.county_id);

                const localities = this.fields.locality_id.options;
                const selectedLocality = localities.find( locality => locality.value === this.model.locality_id);

                let uploadedLogo = null;
                let uploadedCsv = null;

                const payload = {
                    ...this.model,
                    county_name: selectedCounty.text,
                    locality_name: selectedLocality?.text,
                    is_test: !!this.model.is_test,
                }

                const organisation = await this.addInstitution(payload);

                Message.success('INSTITUTION.SUCCESS.ADD_ORG');

                if (organisation && this.logo) {
                    const formData = new FormData();
                    if (this.logo instanceof File) {
                        formData.append("files", this.logo);
                    }

                    uploadedLogo = await this.doUpload({
                        files: formData,
                        uploadAccessToken: this.uploadAccessToken,
                    });

                    let logo = uploadedLogo.map((el) => ({file_id: el.id}))

                    await this.doUpdate({
                        id: organisation.id,
                        payload: {
                            logo: logo[0].file_id
                        },
                    });
                }

                if (organisation && this.usefulLinksCsv) {
                    const formDataCsv = new FormData();

                    if (this.usefulLinksCsv instanceof File) {
                        formDataCsv.append("files", this.usefulLinksCsv);
                    }

                    uploadedCsv = await this.doUpload({
                        files: formDataCsv,
                        uploadAccessToken: this.uploadAccessToken,
                    });

                    let csvFile = uploadedCsv.map((el) => ({file_id: el.id}))

                    await this.doUpdate({
                        id: organisation.id,
                        payload: {
                            useful_links_csv: csvFile[0].file_id
                        },
                    });
                }

                this.$emit('submit');
                this.hide();
            }
        },
        hide() {
            this.$emit('cancelForm');
        },
        onChange(value, field) {
            this.fv.revalidateField(field.name, true);
            if (field?.children) {
                field.children.forEach((child) => {
                    const el = this.fields[child.name];
                    el.fetchParams = { [child.key] : value };
                    this.model[child.name] = '';
                    el.updateOptions();
                    if (this.fv.fields[child.name]) {
                        this.fv.revalidateField(child.name, true);
                    }
                });
            }
        },
        async uploadLogo(documents) {
            if (documents?.length && documents.length === 1) {
                this.logo = documents[0];
            } else {
                this.logo = null;
            }
        },
        async uploadUsefulLinksCsv(csvFiles) {
            if (csvFiles?.length && csvFiles.length === 1) {
                this.usefulLinksCsv = csvFiles[0];
            } else {
                this.usefulLinksCsv = null;
            }
        },
    },
    created() {
        const record = {
            county_id: this.defaultCountyId,
            status: 'active',
            test_org: false,
        };
        this.model = formSchema.initialValues(record);
    },
    mounted() {
        this.fv = createFormValidation('st_add_institution_form', this.rules);
        this.fv.disableValidator(fields.locality_id.name);
        for (let field in fields) {
            if (fields[field]?.children) {
                fields[field].children.forEach((child) => {
                    const el = fields[child.name];
                    el.fetchParams = { [child.key] : this.model[fields[field].name] };
                    el.updateOptions();
                });
            }
        }
        this.model.op_details_text = `Pentru depunerea cererii online taxele se pot plăti prin ordin de plată. Online prin transfer bancar sau fizic de la ghișeul băncilor direct în contul XXXXXXXX (cod IBAN XXXXXXXXXXXXXXXX, CIF XXXXXX). În formularul de plată se completează tipul și obiectivul cererii.`;
    },
    watch: {
        'model.type': {
            handler() {
                if (this.model.type === 'city_hall') {
                    this.fv.enableValidator(fields.locality_id.name);
                } else {
                    this.fv.disableValidator(fields.locality_id.name);
                }
            },
        },
        'model.status': {
            handler() {
                if (this.model.status === this.inactiveStatus) {
                    this.model.is_citizen_visible = false;
                }
            }
        }
    },
}
</script>
